import React, {useEffect, useRef} from "react";
import { Link as LinkScroll, Element, animateScroll, scroller } from "react-scroll";
import Link from "next/link";
import { Carousel } from "./Carousel";

import { Sales } from "../Home/Sales";

import { SubMenu } from "../../components/SubMenu";

import { useStore } from "../../context/state";
import { useRouter } from "../../hooks/useRouter";

import Button from "@material-ui/core/Button";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";

export const Page = ({
  categoryList,
  productDictByCategory,
  filters,
  storeSettings,
  onItemClick,
}) => {
  const { isMobile } = useStore();
  const router = useRouter();
  const menuRef = useRef(null);

  useEffect(() => {
      if (router.asPath.indexOf("#") === -1) {
          return;
      }
      const hash = router.asPath.split("#")[1].split("?")[0];

      if (hash === "menu") {
          menuRef.current.scrollIntoView();
          return;
      }

      setTimeout(() => {
          animateScroll.scrollTo(hash, {
              duration: 500,
              delay: 100,
              smooth: true,
              containerId: hash,
              offset: -40
            });
      }, 1000);
  }, [router.asPath]);


  let content = (
    <>
      {categoryList.map((category, i) => {
        const id = category.ecommerce_product_category_id;
        const name = category.name;

        const productList =
          productDictByCategory[category.ecommerce_product_category_id] || [];
        return (
          <Sales
            category={id}
            columns={3}
            filters={filters}
            size={20}
            noPrice
            itemId="ecommerce_product_id"
            labelClassName=""
            onItemClick={(id, e, t, isDetails) => onItemClick(id, e, t, isDetails)}
            label={
            <div>
                <a className={
                  isMobile
                    ? "u-absolute u-top0 u-sizeFullWidth u-flexCenter u-justifyContentCenter"
                    : "u-marginBottom20"
                }
              >
                <div
                  id={id}
                  name={id}
                  className="u-textColorPrimary u-paddingHorizontal20 u-paddingVertical5 u-xs-textAlignCenter"
                  style={{ fontFamily: "Piroshky", letterSpacing: "2px", fontSize: "30px" }}
                >
                  {name}
                </div>
                </a>
              </div>
            }
            productList={productList}
          />
        );
      })}
    </>
  );

  return (
    <div className="menu-page product-list-page background-wrapper rocket-layout">
      <div className="container u-xs-padding0">
        <div
          className="paper-box u-xs-padding0"
          style={{ minHeight: "calc(100vh - 65px)" }}
        >
          <div className="u-xs-paddingHorizontal24">
            <SubMenu />
          </div>
          <div>
            <Carousel
              storeSettings={storeSettings}
              height={isMobile ? "550px" : "500px"}
            />
          </div>
          <div className="container u-marginTop30 u-xs-marginTop30 u-flexCenter u-flexWrap u-xs-paddingHorizontal24">
            {categoryList.map((category, i) => {
              const id = category.ecommerce_product_category_id;
              const name = category.name;
              return (
                <LinkScroll
                  key={i}
                  activeClass="active"
                  to={id}
                  spy={true}
                  smooth={true}
                  offset={-20}
                  className="u-border1 u-borderColorLighter u-backgroundColorWhite u-textColorDark u-borderRadius30 u-paddingHorizontal12 u-paddingVertical5 u-marginBottom10 u-marginRight10 u-cursorPointer"
                  duration={500}
                >
                  <span>{name}</span>
                </LinkScroll>
              );
            })}
          </div>
          <div ref={menuRef} className="container u-marginTop20 u-xs-marginTop30">
              {content}
          </div>
        </div>
      </div>
    </div>
  );
};
